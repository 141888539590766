<!-- 订单详情 -->
<template>
  <div class="orderDetail">
    <div class="carInfo">
      <img class="img" :src="source.whiteImg" />
      <div class="right">
        <div class="title van-multi-ellipsis--l2">{{ carname }}</div>
        <div class="price">厂商指导价：{{ source.referPrice }} 万</div>
      </div>
    </div>
    <div class="order">
      <div class="item">
        <div class="left">订单编号</div>
        <div class="right">{{ source.clueId }}</div>
      </div>
      <div class="item">
        <div class="left">下单时间</div>
        <div class="right">{{ source.time }}</div>
      </div>
    </div>
    <div class="plan">
      <div class="title">分期方案</div>
      <div class="item" v-for="item in keyList" :key="item.key">
        <div class="left">{{ item.name }}</div>
        <div class="right">
          {{ source[item.key] ? source[item.key] + item.unit : "" }}
        </div>
      </div>
    </div>

    <div v-show="source.deleteSign === '1'" class="btn" @click="delClick">
      删除
    </div>
    <PopupModal title="提示" :showModal="showModal" content="订单删除后不可恢复，是否确认删除订单？" :confirmClick="confirm" :cancelClick="hideModal" :showTwoBtn="true" />
  </div>
</template>

<script>
import { getOrderDetail, deleteOrder } from "@/api/api_personal";
import PopupModal from "@/components/PopupModal";

export default {
  name: "orderDetail",
  data () {
    return {
      id: this.$route.query.id,
      source: {},
      keyList: [
        { name: "方案名称", key: "planName", unit: "" },
        { name: "首付比例", key: "downPaymentRatio", unit: "" },
        { name: "利率", key: "productRateDesc", unit: "%" },
        { name: "期数", key: "productLoanPeriodDesc", unit: "月" },
        { name: "融资额度", key: "financingAmount", unit: "万元" }
      ],
      showModal: false
    };
  },
  created () {
    this.getInfo();
  },
  computed: {
    carname () {
      return `${this.source.masterBrandName} ${this.source.serialName} ${this.source.year} ${this.source.styleName}`;
    }
  },
  methods: {
    getInfo () {
      getOrderDetail({ clueId: this.id }).then(res => {
        if (res.code === 0) {
          this.source = res.data.info;
        }
      });
    },
    delClick () {
      this.showModal = true;
    },
    confirm () {
      deleteOrder({ clueId: this.id }).then(res => {
        this.showModal = false;
        if (res.code === 0) {
          const that = this;
          this.$toast({
            type: "success",
            message: "订单删除成功",
            duration: 1500,
            onClose () {
              that.$router.go(-1);
            }
          });
        }
      });
    },
    hideModal () {
      this.showModal = false;
    }
  },
  components: { PopupModal }
};
</script>
<style lang="scss" scoped>
.orderDetail {
  height: 100%;
  background: #fff;
  .carInfo {
    padding: 0.16rem 0;
    margin: 0 0.18rem;
    border-bottom: 1px solid #f1f2f6;
    display: flex;
    .img {
      width: 0.96rem;
      height: 0.64rem;
      margin-right: 0.12rem;
    }
    .title {
      font-size: 0.15rem;
      line-height: 0.21rem;
      font-weight: bold;
    }
    .price {
      font-size: 0.13rem;
      line-height: 0.18rem;
      color: #3a3d46;
      margin-top: 0.04rem;
    }
  }
  .order {
    padding: 0.1rem 0.18rem;
    position: relative;
    &::after {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      height: 0.1rem;
      bottom: -0.1rem;
      background: #f8f8f8;
    }
  }
  .plan {
    margin-top: 0.1rem;
    padding: 0.16rem 0.18rem 0;
    .title {
      font-size: 0.15rem;
      line-height: 0.21rem;
      font-weight: bold;
      margin-bottom: 0.03rem;
    }
  }
  .item {
    font-size: 0.13rem;
    line-height: 0.18rem;
    color: #3a3d46;
    display: flex;
    justify-content: space-between;
    padding: 0.06rem 0;
  }
  .btn {
    background-image: linear-gradient(to right, #ff8f6e, #fd4b4b);
    border-radius: 0.29rem;
    height: 0.54rem;
    text-align: center;
    color: white;
    text-align: center;
    line-height: 0.54rem;
    text-indent: 0rem;
    right: 0.18rem;
    bottom: 0.18rem;
    left: 0.18rem;
    position: absolute;
  }
}
</style>
